exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* lovot color */ /* #FF8E69 */ /* #FFFFFF */ /* #000000 */ /* #5F9BC6 */ /* #EBBC4E */ /* #74956C */ /* #F09491 */ /* #8E7FAE */ /* #5E514D */ /* overwrite color */ /* #FF8157 */ /* 90% transparent version of motion-primary */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* #0FBD8C */ /* #0FBD8C */ /* lighter than motion-primary */ /* #BD42BD */ .waveform_container_2K9Om {\n    width: 100%;\n} .waveform_waveform-path_TskyB {\n    /*\n        This color is lighter than sound-primary, but\n        cannot use alpha because of overlapping elements.\n    */\n    fill: hsl(0, 0%, 80%);\n    stroke: hsla(0, 0%, 10%, 1);\n} .waveform_baseline_2J5dw {\n    stroke: hsla(0, 0%, 10%, 1);\n}\n", ""]);

// exports
exports.locals = {
	"container": "waveform_container_2K9Om",
	"waveform-path": "waveform_waveform-path_TskyB",
	"waveformPath": "waveform_waveform-path_TskyB",
	"baseline": "waveform_baseline_2J5dw"
};