import PropTypes from 'prop-types';
import React from 'react';

import locales from 'scratch-l10n';
import styles from './language-selector.css';

// supported languages to exclude from the menu, but allow as a URL option
// https://github.com/LLK/scratch-l10n/blob/master/src/supported-locales.js
const ignore = [];
const available = ['ja', 'ja-Hira', 'en', 'zh-cn'];

const LanguageSelector = ({ currentLocale, label, onChange }) => (
    <select
        aria-label={label}
        className={styles.languageSelect}
        value={currentLocale}
        onChange={onChange}
    >
        {
            Object.keys(locales)
                .filter(l => available.includes(l))
                .map(locale => (
                    <option
                        key={locale}
                        value={locale}
                    >
                        {locales[locale].name}
                    </option>
                ))
        }
    </select>
);

LanguageSelector.propTypes = {
    currentLocale: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};

export default LanguageSelector;



