const idTokenKey = "id_token";
const lovotKey = "lovot"
import firebase from 'firebase'

let url = new URL(window.location);

var firebaseConfig = {};
var authUrl = "";

if (url.hostname.includes("play.lovot.life")) {
    // production
    authUrl = "https://id.lovot.life/";
    firebaseConfig = {
        apiKey: "AIzaSyBy0ulfJ9v7NipJqlkByIch6clh338JUN4",
        authDomain: "gx-cloud-jp-prod-app.firebaseapp.com",
        databaseURL: "https://gx-cloud-jp-prod-app.firebaseio.com",
        projectId: "gx-cloud-jp-prod-app",
        storageBucket: "gx-cloud-jp-prod-app.appspot.com",
        messagingSenderId: "792121528533",
        appId: "1:792121528533:web:7b917938d316236737d25c"
    };
} else if (url.hostname.includes("jp-stg1-dot-lovot-playground.appspot.com")) {
    // jp-stg1
    authUrl = "https://id.jp-stg1.lovot.life/";
    firebaseConfig = {
        apiKey: "AIzaSyDa9oYBsQbQY3nIOjqC9mZjkT5VUX-mTOA",
        authDomain: "gx-cloud-jp-stg1-app.firebaseapp.com",
        databaseURL: "https://gx-cloud-jp-stg1-app.firebaseio.com",
        projectId: "gx-cloud-jp-stg1-app",
        storageBucket: "gx-cloud-jp-stg1-app.appspot.com",
        messagingSenderId: "200989774290",
        appId: "1:200989774290:web:acf67debaf735211aeb80b"
    };
} else if (url.hostname.includes("jp-stg2-dot-lovot-playground.appspot.com")) {
    // jp-stg2
    authUrl = "https://id.jp-stg2.lovot.life/";
    firebaseConfig = {
        apiKey: "AIzaSyDcFParokzK9eZSUzGtZtFf0DQBgaxSNZI",
        authDomain: "gx-cloud-jp-stg2-app.firebaseapp.com",
        databaseURL: "https://gx-cloud-jp-stg2-app.firebaseio.com",
        projectId: "gx-cloud-jp-stg2-app",
        storageBucket: "gx-cloud-jp-stg2-app.appspot.com",
        messagingSenderId: "408951625690",
        appId: "1:408951625690:web:3e6273c8274e46364892e3"
    };
} else if (url.hostname.includes('dev-dot-lovot-playground.appspot.com')) {
    // dev
    authUrl = 'https://id.dev1.lovot.life/';
    firebaseConfig = {
        apiKey: 'AIzaSyC3vPpEUJLKUUxeRwyK_hkEnLy1ctDldmk',
        authDomain: 'gx-cloud-dev-app.firebaseapp.com',
        databaseURL: 'https://gx-cloud-dev-app.firebaseio.com',
        projectId: 'gx-cloud-dev-app',
        storageBucket: 'gx-cloud-dev-app.appspot.com',
        messagingSenderId: '584148448839',
        appId: '1:584148448839:web:d941188de7b0600040a362',
        measurementId: 'G-YXP8C3K45S'
    };
} else {
    authUrl = "https://id.jp-stg1.lovot.life/";
    firebaseConfig = {
        apiKey: "AIzaSyDa9oYBsQbQY3nIOjqC9mZjkT5VUX-mTOA",
        authDomain: "gx-cloud-jp-stg1-app.firebaseapp.com",
        databaseURL: "https://gx-cloud-jp-stg1-app.firebaseio.com",
        projectId: "gx-cloud-jp-stg1-app",
        storageBucket: "gx-cloud-jp-stg1-app.appspot.com",
        messagingSenderId: "200989774290",
        appId: "1:200989774290:web:acf67debaf735211aeb80b"
    };
}

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}

const validateLovotAvailable = function () {
    // 接続先の情報を取得する。
    let lovot = localStorage.getItem(lovotKey);
    if (!lovot || lovot.length == 0) {
        location.href = "/static/auth.html";
    }
}

const signOut = function () {
    firebase.auth().onAuthStateChanged((user) => {
        firebase.auth().signOut().catch((error) => {
            console.log("failed to sign out",error);
        });
        localStorage.removeItem(lovotKey);
        localStorage.removeItem(idTokenKey);
        location.href = authUrl + "logout";
    });
}

export default {
    validateLovotAvailable,
    signOut
}
