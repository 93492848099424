exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* lovot color */ /* #FF8E69 */ /* #FFFFFF */ /* #000000 */ /* #5F9BC6 */ /* #EBBC4E */ /* #74956C */ /* #F09491 */ /* #8E7FAE */ /* #5E514D */ /* overwrite color */ /* #FF8157 */ /* 90% transparent version of motion-primary */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* #0FBD8C */ /* #0FBD8C */ /* lighter than motion-primary */ /* #BD42BD */ .green-flag_green-flag_1kiAo {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    user-drag: none;\n    cursor: pointer;\n} .green-flag_green-flag_1kiAo:hover {\n    background-color: hsla(15, 100%, 67%, 0.15);\n} .green-flag_green-flag_1kiAo.green-flag_is-active_2oExT {\n    background-color: hsla(15, 100%, 67%, 0.35);\n}\n", ""]);

// exports
exports.locals = {
	"green-flag": "green-flag_green-flag_1kiAo",
	"greenFlag": "green-flag_green-flag_1kiAo",
	"is-active": "green-flag_is-active_2oExT",
	"isActive": "green-flag_is-active_2oExT"
};